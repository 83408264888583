import React, {useState, useEffect, useRef} from "react"
import styled from 'styled-components';
import AOS from 'aos';
import getFirebase from 'src/utilities/firebase'
import withSize from 'src/utilities/withSize'
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import LinkButton from 'src/components/shared/LinkButton'
import ThemeCarousel from 'src/components/ThemeCarousel'
import SupportHelmet from 'src/components/SupportHelmet'
import LoginModal from 'src/components/LoginModal'
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import { identify, trackPage, trackEvent, getPropertiesFromLocation, } from 'src/utilities/analytics'
import { createBetaUserSubscription } from 'src/utilities/http-requests'
import { createResume } from 'src/utilities/firestore'
import {
  Link,
  navigate,
  graphql,
  useStaticQuery,
} from "gatsby"
import Img from "gatsby-image"
import MediaPanel from 'src/components/landing-page/MediaPanel'
import ReviewsPanel from 'src/components/landing-page/ReviewsPanel'
import "aos/dist/aos.css";

const isBeta = false

// queries for desktop and mobile images
export const imageQuery = graphql`
  query {
    desktopImage: file(relativePath: { eq: "katherine-davis-1850x843.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    mobileImage: file(relativePath: { eq: "katherine-davis-1850x843.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ResumeImageContainer = styled.div`
`

const ResumeImageLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ResumeImg = styled(Img)`
  width: 770px;
  border-radius: 8px 8px 0 0;

  @media only screen and (max-width: 1023px) {
    max-width: 420px;
    width: 100%;
    border-radius: 4px 4px 0 0;
  }

`

const StyledH1 = styled('h1')`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  color: #1a1c6a;
`

const StyledH2 = styled('h2')`
  font-size: 46px;
  font-weight: 700;

  @media only screen and (max-width: 767px) {
    font-size: 32px;
  }
`

// this container is to hide the animating resume image
// otherwise it animates over the elements below as it moves up
const BottomContainer = styled.div`
  z-index: 1;
  background-color: var(--neutral-10);
`

const WelcomeContainer = styled('section')`
  background-color: var(--neutral-10);
`
const Welcome = ({
  currentUser,
  productTerm,
  resumeTerm,
}) => {

  const ctaDestinationURL = '/resume-templates'

  // responsive images:
  const imageData = useStaticQuery(imageQuery)
  const imageSources = [
    imageData.mobileImage.childImageSharp.fluid,
    {
      ...imageData.desktopImage.childImageSharp.fluid,
      media: `(min-width: 1023px)`,
    },
  ]

  return (
    <WelcomeContainer>
      <div className="container pt-8 pt-md-9">
        <div className="row align-items-center">
          <div className="col-12">
            <StyledH1 className='text-center mb-1'>
              Free {productTerm}
            </StyledH1>
            <StyledH2 className='text-center mt-1 h1'>
              Are You Serious About Getting the Job?
            </StyledH2>
            <p className="lead text-center mb-5">
              80% of applicants who submit a {resumeTerm.lowercasedSingular} are never invited to interview. <br/>
              Beat that 80%.
            </p>
            <div className="text-center mb-5">
              <LinkButton
                to={ctaDestinationURL}
                size='xl'
              >
                Build Your {resumeTerm.capitalizedSingular} Now
              </LinkButton>
            </div>
          </div>
          <ResumeImageContainer
            className="col-12 order-md-2 text-center"
          >
            <ResumeImageLink
              to={ctaDestinationURL}
              data-aos="slide-up"
              data-aos-duration="700"
              data-aos-easing="ease-out-sine"
            >
              <ResumeImg
                fluid={imageSources}
                alt="Build your resume now"
                loading='eager'
              />
            </ResumeImageLink>
          </ResumeImageContainer>
        </div>
      </div>
    </WelcomeContainer>
  )
}

const Feature = ({title, children, image}) => {
  return (
    <div className="col-12 col-md-4" data-aos="fade-up">
    <div className="icon text-primary mb-3">
      {/* image */}
    </div>
    <h3>
      {title}
    </h3>
    <p className="text-muted mb-6 mb-md-0">
      {children}
    </p>
  </div>
  )
}

const FeatureLink = styled(Link)`
  font-weight: 600;
  color: var(--gray-dark);
`

const Features = ({
  resumeTerm,
}) => {
  return (
    <section className="py-8 py-md-9 bg-white">
      <div className="container">
        <div className="row">
          <Feature
            title='Not Hearing Back on Your Job Applications?'
          >
            Your {resumeTerm.lowercasedSingular} is likely getting filtered out automatically by ATS.
            ATS (Applicant Tracking Software) is used in over 98% of Fortune 500 recruitment processes
            and automatically filters out 75% of submitted {resumeTerm.lowercasedPlural}.
            Most job postings get too many irrelevant applications for a hiring manager to read.
            ATS quickly filters out {resumeTerm.lowercasedPlural} that are missing keywords or formatted incorrectly.
            Our <FeatureLink to='/resume-templates'>{resumeTerm.lowercasedSingular} templates</FeatureLink> and <FeatureLink to='/resume-templates'>keyword recommender</FeatureLink> are optimized to get
            your {resumeTerm.lowercasedSingular} through ATS filters and into the hands of hiring managers.
          </Feature>
          <Feature
            title='Are You Standing Out From the Crowd?'
          >
            Only 2–3% of candidates who apply to a job posting are invited to interview.
            An average job posting gets about 250 {resumeTerm.lowercasedSingular} submissions.
            Still, most employers will shortlist less than 10 candidates.
            Our <FeatureLink to='/resume-templates'>{resumeTerm.lowercasedSingular} templates</FeatureLink> and
            <FeatureLink to='/resume-templates'> writing tools</FeatureLink> allow you to create a top 1%
            {resumeTerm.lowercasedSingular} with professionally polished design, and recruiter-recommended keywords and phrases.
            You can stand out from the 98% and maximize your chances of getting called in to interview
            with a distinctive, effectively written {resumeTerm.lowercasedSingular}.
          </Feature>
          <Feature
            title={`Is Writing Your ${resumeTerm.lowercasedSingular} Taking Awhile?`}
          >
            Most people take between 6 and 8 hours to write a {resumeTerm.lowercasedSingular}.
            Our <FeatureLink to='/resume-templates'>{resumeTerm.lowercasedSingular} writing tools</FeatureLink> bring this time down to less than 1 hour.
            Simply fill out your profile or <FeatureLink to='/resume-templates'>import from LinkedIn</FeatureLink>. Not sure what to write?
            Our <FeatureLink to='/resume-templates'>content recommender</FeatureLink> provides recruiter-recommended sentences and phrases tailored to you.
            As you fill out your profile, it will be automatically formatted into each of our
            <FeatureLink to='/resume-templates'> professionally-designed {resumeTerm.lowercasedSingular} templates</FeatureLink>.
            Preview changes to your {resumeTerm.lowercasedSingular} as you update your profile.
            Instantly export to PDF, the most widely accepted and recruiter-preferred file format.
          </Feature>
        </div>
      </div>
    </section>
    )
}

const textColor = '#f2f5fa'

const CarouselAndTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #21277f;
    padding-top: 50px;
    padding-bottom: 20px;

    @media screen and (max-width: 1023px) {
      padding-top: 20px;
    }
  `

const TextContainer = styled.div`
    color: ${textColor};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    font-weight: bold;

    @media screen and (max-width: 1023px) {
      text-align: center;
      padding: 0px 20px;
      margin-bottom: 10px;
    }
  `

const CarouselTitle = styled.h2`
    font-weight: 700;

    @media screen and (max-width: 1023px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  `

const CarouselSubtitle = styled.h3`

    @media screen and (max-width: 1023px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  `

const CarouselContainer = ({
  size,
  onClickSlide,
  className,
  resumeTerm,
}) => {

  const {
    width,
  } = size

  const carouselStyle = {
    width: (width || 1600) - 60,
    backgroundColor: '#21277f',
  }

  // use width to determine slide size. height will include header text, so not so useful.

  // want image container to be about a third of width with margin
  // then make height proportional
  // max 450

  const heightToWidthMultiplier = .70735

  let imageContainerWidth = Math.min((width - 60) / 3, 450)
  let imageContainerHeight = imageContainerWidth / heightToWidthMultiplier

  // on screens smaller than 1023px
  // carousel takes full width

  // on phone-sized screens (<768 width), resume slide should be full width of screen
  // on ipad-sized screens (769 - 1023 width), resume slide should be 3/5 of width of screen
  // so that there will be larger resume in center, and about 1/3 of a resume on either side

  if (width < 1023) {

    let widthProportion = 3 / 5

    if (width < 768) {
      widthProportion = 1
    }

    imageContainerWidth = width * widthProportion
    imageContainerHeight = imageContainerWidth / heightToWidthMultiplier
    carouselStyle.width = width
  }

  const imageContainerStyle = {
    width: imageContainerWidth,
    height: imageContainerHeight,
  }

  const headerStyle = {
    marginBottom: -20,
  }

  const slideStyleOverrides = {
    titleStyle: {
      color: textColor,
    },
  }
  return (
    <CarouselAndTextContainer className={className}>
      <TextContainer>
        <CarouselTitle>
          Beautiful, ready-to-use {resumeTerm.lowercasedSingular} templates
        </CarouselTitle>
        <CarouselSubtitle>
          Professionally-designed {resumeTerm.lowercasedSingular} templates. Recruiter recommended. Download to PDF in 1 click.
        </CarouselSubtitle>
      </TextContainer>
      <ThemeCarousel
        heightProportion={40}
        style={carouselStyle}
        {...{onClickSlide, imageContainerStyle, headerStyle, slideStyleOverrides}}
      />
    </CarouselAndTextContainer>
    )
}

const StyledCarouselContainer = styled(CarouselContainer)`
`
const CarouselContainerWithSize = withSize(StyledCarouselContainer)

/*
Landing Page component allows for easy creation of landing page variants,
primarily for messaging consistency and better ad quality score (consistency with ad language)

productTerm allows for switching between terms like
'Resume Builder', 'CV Builder', 'Resume Maker', etc

resumeTerm allows for switching between 'resume', 'CV', etc

pageName allows for changing the page name reported in analytics

*/
const LandingPage = ({
  location,
  // Resume Builder vs CV Builder vs Resume Maker, etc, to correspond to term in cpc ad
  productTerm='Resume Builder',
  resumeTerm={
    lowercasedSingular: 'resume',
    capitalizedSingular: 'Resume',
    lowercasedPlural: 'resumes',
    capitalizedPlural: 'Resumes',
  },
  pageName='Home',
}) => {

  console.log('location: ', location)

  const firebase = getFirebase();
  const [currentUser, setCurrentUser] = useState(null)
  const selectedThemeRef = useRef()

  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      identify(user)
      attemptCreateResume({currentUser: user})
    });
    }, [firebase]);

  useEffect(() => {
    trackPage(
      pageName,
      getPropertiesFromLocation(location),
    )
  }, [])

  // animate on scroll
  useEffect(() => {
    AOS.init({
      duration : 1000,
      once: true,
    });
    AOS.refresh();
  }, []);

  // create a resume if logged in and resume selected
  // call this function when either necessary condition updates:
  // template selected or user logged in
  // currentUser as state won't necessarily update synchronously
  // so this function takes it directly. see onAuthStateChanged
  const attemptCreateResume = ({currentUser}) => {

    if (!selectedThemeRef.current) {
      return
    }

    if (!currentUser) {
      setLoginModalIsOpen(true)
      return
    }

    let betaUserSubscriptionPromise = Promise.resolve(null)
    if (isBeta && currentUser) {
      betaUserSubscriptionPromise = createBetaUserSubscription({userUID: currentUser.uid})
                                      .then(() => console.log('created beta user subscription'))
    }

    const resumeInfo = {
      metadata: {
        theme: selectedThemeRef.current
      }
    }
    const createResumePromise = createResume({
      ownerUID: currentUser.uid,
      resumeInfo
    })

    Promise.all([betaUserSubscriptionPromise, createResumePromise])
    .then(([empty, docRef]) => {
      navigate(`/resumes/${docRef.id}`)
    })
    .catch(error => console.error("Error creating resume: ", error))
  }

  const onClickSlide = ({id, source}) => {
    selectedThemeRef.current = id
    attemptCreateResume({currentUser})
    trackEvent('clicked_select_template', {
      page: pageName,
      template_id: id,
      source,
    })
  }
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false)
  const onRequestClose = () => {
    setLoginModalIsOpen(false)
  }

  // do nothing. handle login in auth state changed hook
  const signInSuccessWithAuthResult = () => false

  return (
    <Layout
      onSignInSuccess={() => navigate('/resumes')}
    >
      <SEO title='Build a Beautiful Resume in 5 Minutes' />
      <SupportHelmet/>
      <AnalyticsHelmet/>
      <LoginModal
        isOpen={loginModalIsOpen}
        shouldAllowSkipLogin={true}
        {...{ firebase, onRequestClose, signInSuccessWithAuthResult }}
      />
      <Welcome
        {...{
          currentUser,
          productTerm,
          resumeTerm,
        }}
      />
      <BottomContainer>
        <MediaPanel/>
        <ReviewsPanel/>
        <Features
          {...{
            resumeTerm,
          }}
        />
        <CarouselContainerWithSize
          {...{
            onClickSlide,
            resumeTerm,
          }}
        />
      </BottomContainer>

    </Layout>
  )
}

export default LandingPage
