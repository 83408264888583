import React from "react"
import styled from 'styled-components';
import { Link } from "gatsby"

// links are good for SEO and other things
// this link looks like a button

const fontSizeMap = {
  'xl': '24px',
  'xl-mobile': '18px',
}

const fontWeightMap = {
  'xl': '600',
  'xl-mobile': '600',
}

const paddingMap = {
  'xl': '20px 30px',
  'xl-mobile': '14px 24px',
}

const StyledLink = styled(Link)`
  font-size: ${props => fontSizeMap[props.size]};
  font-weight: ${props => fontWeightMap[props.size]};
  padding: ${props => paddingMap[props.size]};


  @media only screen and (max-width: 767px) {
    font-size: ${props => fontSizeMap[`${props.size}-mobile`]};
    font-weight: ${props => fontWeightMap[`${props.size}-mobile`]};
    padding: ${props => paddingMap[`${props.size}-mobile`]};
  }
`

const LinkButton = ({variant='primary', size, to, className='', children, }) => {
  const variantClassMap = {
    'primary': 'btn-primary',
    'secondary': 'btn-secondary',
    'outline-primary': 'btn-outline-primary',
    'outline-secondary': 'btn-outline-secondary',
  }
  const sizeClassMap = {
    'xs': 'btn-xs',
    'sm': 'btn-sm',
    'lg': 'btn-lg',
  }
  const variantClassName = variantClassMap[variant]
  const sizeClassName = size ? sizeClassMap[size] : ''
  const _className = `btn ${variantClassName} ${sizeClassName} ${className}`

  return (
    <StyledLink
      {...{
        to,
        size,
      }}
      className={_className}
    >
      {children}
    </StyledLink>
    )
}

export default LinkButton
