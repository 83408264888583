import React, {useState, useEffect, useRef} from "react"
import styled, { css } from 'styled-components';

import BusinessDotCom from 'src/images/companies/business-dot-com.svg'
import FinancialTimes from 'src/images/companies/financial-times.svg'
import Forbes from 'src/images/companies/forbes.svg'
import Huffpost from 'src/images/companies/huffpost.svg'
import Lifehacker from 'src/images/companies/lifehacker.svg'
import TheGuardian from 'src/images/companies/the-guardian.svg'

const Container = styled('section')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: white;
  border-top: 1px solid rgba(35,49,67,.05);
  border-bottom: 1px solid rgba(35,49,67,.05);
  padding: 15px 0;

  @media only screen and (min-width: 768px) {
    padding: 20px 10px;
  }

  @media only screen and (min-width: 1140px) {
    padding: 40px 20px;
  }
`

const Content = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1;

  @media only screen and (min-width: 768px) {
    max-width: 1020px;
  }

  @media only screen and (min-width: 1140px) {
    max-width: 1170px;
    justify-content: space-between;
  }
`

const logoStyle = css`
  margin: 15px 20px;
  height: 16.5px;

  @media only screen and (min-width: 768px) {
    margin: 10px 20px;
  }

  @media only screen and (min-width: 1140px) {
    height: 20px;
    margin: 0;
  }
`

const StyledTheGuardian = styled(TheGuardian)`
  ${logoStyle}
`

const StyledHuffpost = styled(Huffpost)`
  ${logoStyle}
`

const StyledLifehacker = styled(Lifehacker)`
  ${logoStyle}
`

const StyledBusinessDotCom = styled(BusinessDotCom)`
  ${logoStyle}
`

const StyledFinancialTimes = styled(FinancialTimes)`
  ${logoStyle}
`

const StyledForbes = styled(Forbes)`
  ${logoStyle}
`

// TO DO: link out

const MediaPanel = () => {

  return (
    <Container>
      <Content>
        <StyledTheGuardian/>
        <StyledHuffpost/>
        <StyledLifehacker/>
        <StyledBusinessDotCom/>
        <StyledFinancialTimes/>
        <StyledForbes/>
      </Content>
    </Container>
    )

}

export default MediaPanel
